<template>
    <div>
      <Footer></Footer>
        <div class="content">
          <div class="content_box">
           <span class="content_box_tilte"> “大唐杯”全国双师型教师新一代信息通信技术大赛</span>
           <el-button type="primary" class="Btn" @click="BtnT">点我进入报名页面</el-button>
           <div class="content_box_BOX"> 
                <span class="content_inform">竞赛介绍</span><br><br>
                <p class="text_t">“大唐杯”全国双师型教师新一代信息通信技术大赛是由工业和信息化部人才交流中心、中国通信企业协会主办，中信科移动通信技术股份有限公司、大唐移动通信设备有限公司、北京市教委北京高校电子信息类专业群承办，北京邮电大学、北方工业大学、武汉职业技术学院等高校协办，面向全国电子信息、通信工程、计算机、人工智能等相关专业教师的技术技能竞赛。</p>
                <p class="text_t"> 大赛以推广信息通信前沿技术、协同高校专业建设、推动行业创新发展为目的，旨在大力振兴双师型教师队伍，不断提升教师专业能力和工程实践创新能力。推进高校“双一流”及“双高”建设，促进电子信息类相关专业教学内容和教学方法的改革创新，推动5G+垂直产业应用创新，促进高校提升信息通信领域的技术、研究创新成果的转化，助力学校相关专业高质量发展。</p>
                <p class="text_t"> 大赛从最初面向通信工程专业教师群体逐步发展为面向全国高校电子信息大类专业群的所有本科和高职教师的综合性技术技能大赛，专业覆盖范围更广。从最初侧重3G、4G通信网络维护和优化方向基本技能的通信网络工程实践赛，逐步发展以新一代通信技术（5G、6G）为核心，融合人工智能技术、大数据技术、计算机技术等专业技术内容，更加匹配社会发展对复合型人才培养的需求。从最初移动通信网络运维和优化的基本技能，发展为以垂直行业应用为导向、侧重专业知识在解决系统复杂工程问题的应用，侧重面向工程系统中真实场景的技术创新及技术研究。+垂直产业应用创新，促进高校提升信息通信领域的技术、研究创新成果的转化，助力学校相关专业高质量发展。</p>
                <p class="text_t"> 大赛始终践行“以赛促教、以赛促学、以赛促建、以赛促练”的办赛理念，通过九年的不断发展，依托大赛平台孵化出由全国重点院校的电子信息类专业的领军教授与学科教师组成的新工科人才培养高峰论坛，涵盖内容从新一代信息技术展望到校企合作卓越拔尖人才、创新应用人才及工匠人才培养等方面。大赛有力支撑了全国高校的“双高”与 “双一流”建设，推动全国落地百余个产教融合基地，构建起互联50余所高校的信息通信技术仿真竞赛教学云网平台。同时大赛已形成了参赛规模逐年增长，研究领域逐年扩大，技术实践逐年深化的良性循环。截至2022年第九届“大唐杯”全国双师型教师新一代信息通信技术大赛，已累计有2000余人次电子信息类专业教师参与竞赛，范围涉及全国31个省市自治区高校学校166所，其中重点院校32所，普通本科院校103所，中高等职业院校31所。+垂直产业应用创新，促进高校提升信息通信领域的技术、研究创新成果的转化，助力学校相关专业高质量发展。</p>

                <span class="content_inform">竞赛特点与影响力</span><br><br>
                <p class="text_t">“大唐杯”全国双师型教师新一代信息通信技术大赛面向电子信息大类，培养信息通信领域合格的技术技能型人才，竞赛方案参考通信工程实践应用案例和场景，利用真设备，真网络，真应用与竞赛模式的结合，有效提升参赛教师的工程技术水平。</p>
                <p class="text_t"> 信息通信类人才培养是复杂的系统性培训，内容交叉了通信技术，计算机技术，网络工程等专业技术，融合了通信工程，电子信息工程，人工智能，自动控制、物联网，车联网等多专业学科，大赛具备以上成套的信息通信类人才培养课程体系，具备工程技术人才培养标准及经验，具备教育教学领域专业教师培养的运营管理及服务经验，还具备相关5G+应用相关相关设备、配套培训平台及信息通信教学产品，及相关设备、网络和应用部署经验，依托行业工程师评价体系，实现了教学工程项目化管理。</p>
                <p class="text_t"> 大赛以提升教师专业技能、协同高校学科建设、推动行业创新发展为目的，促进 DOICT 相关专业教学内容和教学方法的改革创新，推动基于 5G 的行业创新型应用成果转化，以产教融合形式为国家信息通信产业培养急需的电子信息类教育人才。联通多元化官方平台，依托大赛组织高级别行业论坛，贯彻“以赛促学、以赛促教、以赛促练、以赛促建、以赛促改”的原则，结合行业最新发展方向，实现与高校在产教融合与人才培养基地的高层次建设要求。</p>

           </div>
          </div>
  
  
          <div class="content_box">
           <span class="content_box_tilteT"> “大唐杯”全国双师型教师新一代信息通信技术大赛 官方文件发布</span>
           <div class="content_box_BOXTOW"> 
          <img src="./Teachers_img//WenJ.png" class="ss" alt="">
                <span class="content_inform_Tow">官方文件</span>
                <div class="content_text">
                  <p><el-link type="info" @click="openfile1">第九届“大唐杯”全国双师型教师新一代信息通信技术大赛-大赛章程</el-link></p>
                  <p><el-link type="info" @click="openfile2">第九届“大唐杯”全国双师型教师新一代信息通信技术大赛-竞赛大纲</el-link></p>
                  <p><el-link type="info" @click="openfile3">第九届“大唐杯”全国双师型教师新一代信息通信技术大赛竞赛时间变更通知</el-link></p>
                </div>
                <div class="pagination">
                    <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="60">
                  </el-pagination>
                </div>
           </div>
          </div>
        </div>
    <Bottom></Bottom>
    </div>
  </template>
  
  <script>
  import Footer from '@/components/Footer/Footer.vue'
  import Bottom from '@/components/Bottom/Bottom.vue'
  import $ from "jquery";
  export default {
    name: 'Home',
    components: {
      Footer,
      Bottom
    },
    data(){
      return {
  
      }
    },
    methods:{
      BtnT(){
        this.$router.push('/signup')
      },
      openfile1(){
        window.open('./static/Teachers/第九届“大唐杯”全国双师型教师新一代信息通信技术大赛-大赛章程.pdf')
      },
      openfile2(){
        window.open('./static/Teachers/第九届“大唐杯”全国双师型教师新一代信息通信技术大赛-竞赛大纲.pdf')
  
      },
      openfile3(){
        window.open('./static/Teachers/第九届“大唐杯”全国双师型教师新一代信息通信技术大赛竞赛时间变更通知.pdf')
  
      },
    },
    mounted(){
  
    },
    created(){
  
    },
  }
  </script>
  
  <style scoped lang="scss">
  /deep/ .el-table .cell.el-tooltip{
    text-align: left;
  }
  /deep/.el-link__inner{
    color: #000;
    font-size: .16rem;
  }
  /deep/.el-link__inner:hover{
    color: orangered;
  }
  
  @import './Teachers.scss';
  </style>
  